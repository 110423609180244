<template>
  <b-modal
      id="sms-receiver-modal"
      hide-footer
      size="xl"
      title="ลงทะเบียนระบบออโต้ ดึง SMS"
  >
    <b-overlay :show="isFetching">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider
              v-slot="{ errors }"
              name="deviceId"
              rules="required"
          >
            <b-form-group label="Device ID">
              <b-form-input
                  v-model="form.deviceId"
                  :aria-invalid="false"
                  :state="errors[0] ? false : null"
                  name="deviceId"
                  placeholder="Device ID จากแอพ HandySMS"
                  type="text"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <div class="text-right">
            <a class="btn btn-primary mr-2" href="/handy-sms-release-v1.7.apk" download>
              <i class="uil-download-alt"></i>
              ดาวน์โหลดแอพ
            </a>
            <b-button
                v-clipboard:copy="webhookUrl"
                v-clipboard:success="onCopy"
                class="mr-2"
                type="button"
                variant="info">
              คัดลอก URL
            </b-button>
            <b-button :disabled="isFetching" type="submit" variant="success">
              ลงทะเบียน
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';

export default Vue.extend({
  name: 'AutoBankingSmsModal',
  props: {
    agentBankAccountId: {
      type: String,
      required: true
    },
    bankingSmsReceiver: {
      type: Object,
      default: () => ({
        deviceId: '',
      })
    }
  },
  data() {
    return {
      isFetching: false,
      form: {
        deviceId: '',
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    webhookUrl() {
      return process.env.VUE_APP_SMS_HANDLER_URL + '/' + this.userInfo.agentId
    }
  },
  created() {
    this.form = {
      deviceId: this.bankingSmsReceiver?.deviceId || '',
    }
  },
  methods: {
    ...mapActions(['registerSMS', 'fetchBankAccount']),
    async onSubmit() {
      this.isFetching = true
      const response = await this.registerSMS({ id: this.agentBankAccountId, data: this.form })
      if(response) {
        this.$bvModal.hide('sms-receiver-modal')
        await this.fetchBankAccount(this.agentBankAccountId)
      }
      this.isFetching = false
    },
    onCopy() {
      this.$bvToast.toast('คัดลอก URL เรียบร้อย', {
        variant: 'success',
        title: 'Success'
      })
    },
  }
})
</script>

<style scoped>

</style>